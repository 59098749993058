<template>
  <table class="invoices-statement">
    <tbody>
      <tr>
        <td class="row">
          <h3 @mouseleave="explainFundHeld = false">
            Funds Held
            <Icon
              :path="mdiAlertCircleOutline"
              @mouseover="explainFundHeld = true"
              :size="14" />
            <SmallExplainPopup v-show="explainFundHeld">
              This is the money we're holding on your behalf.
            </SmallExplainPopup>
          </h3>
          <InvoicesCalcTable :data="data" />
          <h4>
            {{ formatMoney(data.financial.fundsHeld) }}
          </h4>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import InvoicesCalcTable from "@/components/Invoice/InvoicesCalcTable.vue";
  import { mdiAlertCircleOutline } from "@mdi/js";
  import SmallExplainPopup from "@/components/Dialogs/SmallExplainPopup.vue";

  export default {
    data() {
      return { mdiAlertCircleOutline, explainFundHeld: false };
    },
    props: { data: { type: Object } },
    components: { InvoicesCalcTable, SmallExplainPopup },
  };
</script>
