<template>
  <table class="calc">
    <tr class="formula">
      <td>Adjusted Revenue</td>
      <td>-</td>
      <td>Commissions</td>
      <td>-</td>
      <td>Processing Fee</td>
      <td>-</td>
      <td>Promotion Contribution</td>
    </tr>
    <tr class="values">
      <td>
        {{ formatMoney(data.financial.adjustedTotal) }}
      </td>
      <td>-</td>
      <td>{{ formatMoney(data.financial.commissionTotal) }}</td>
      <td>-</td>
      <td>
        {{ formatMoney(data.financial.processingFee.total) }}
      </td>
      <td>-</td>
      <td>
        {{ formatMoney(data.financial.promotionContribution) }}
      </td>
    </tr>
  </table>
</template>

<script>
  export default {
    props: { data: { type: Object } },
  };
</script>

<style lang="scss" scoped>
  table.calc {
    @include calc-table;
  }
</style>
