<template>
  <button
    type="button"
    class="filter-toggle"
    :class="{ active }"
    @click="$emit('click', 'failed')"
  >
    {{ text }}
    <Transition name="fade">
      <Icon
        :path="mdiClose"
        :size="14"
        v-if="active"
      />
    </Transition>

  </button>
</template>
<script>
import { mdiClose } from '@mdi/js';

export default {
  data() {
    return { mdiClose }
  },
  emits: ["click"],

  props: {
    text: {
      value: String,
    },
    active: {
      type: Boolean
    }
  }
}
</script>

<style lang="scss">
.filter-toggle {
  @include flex-center($gap: 0.25rem);
  
  border-radius: none;
  text-transform: capitalize;
  background: none !important;
  color: $col_calendar_dayname;
  padding: 0 0.25rem;

  &:hover {
    background: none !important;
  }

  &.active {
    background: $col_beta;
    color: $col_softblack;
  }
}
</style>
