<template>
  <div class="statement__summary-counts">
    <div
      class="statement__summary-data"
      v-for="(value, key) in data.count"
      :key="key">
      <p class="title">{{ upperCamelCaseToString(key) }}</p>
      <p class="value">{{ value }}</p>
    </div>
    <div class="statement__summary-data rate">
      <p class="title" @mouseleave="explainRate = false">
        Cart Conversion Rate
        <Icon
          :path="mdiAlertCircleOutline"
          :size="14"
          @mouseover="explainRate = true" />
      </p>

      <p class="value">{{ data.cartConversionRate }}</p>
      <SmallExplainPopup class="row" v-show="explainRate">
        <span class="column formula">
          Cart Initiated <span class="divide"></span>
          Order Completed
        </span>
        × 100%
      </SmallExplainPopup>
    </div>
  </div>
</template>

<script>
  import { mdiAlertCircleOutline } from "@mdi/js";
  import { upperCamelCaseToString } from "@/helpers/stringFormatter.js";
  import SmallExplainPopup from "@/components/Dialogs/SmallExplainPopup.vue";

  export default {
    data() {
      return { mdiAlertCircleOutline, explainRate: false };
    },
    props: { data: { type: Object } },
    methods: { upperCamelCaseToString },
    components: { SmallExplainPopup },
  };
</script>

<style lang="scss">
  .statement__summary {
    padding: 0.5rem;
    border: $table_border;
    width: 100%;
    @include flex-initial;

    &-finance,
    &-counts {
      @include flex-initial($wrap: wrap);
    }

    &-finance {
      border-right: $table_border;
      padding-right: 1rem;
    }

    &-data {
      text-transform: capitalize;
      min-width: 130px;
      @include flex-center($jc: flex-start, $direction: column);

      &.finance {
        border-right: $table_border;
        padding-right: 1.5rem;
      }

      p {
        margin: 0;
        line-height: 1;
        &.title {
          @include flex-initial($gap: 0.25rem, $alignItems: center);
          color: $col_faded-darker;
          font-size: 0.75rem;
        }
        &.value {
          font-weight: 600;
          font-size: 1.1rem;
        }
      }

      &.rate {
        flex-wrap: wrap;
        position: relative;
      }
    }
  }
</style>
