<template>
  <div
    v-if="params.value"
    class="entity"
    :class="params.value"
  >
    <Icon
      :path="mdiArrowRight"
      :size="16"
    />
    {{ params.value }}
  </div>
  <div v-else>-</div>
</template>

<script>
import { mdiArrowRight } from '@mdi/js';

export default {
  data() {
    return {
      mdiArrowRight
    }
  },
}
</script>

<style lang="scss">
.entity {
  @include flex-center($wrap: nowrap);

  .mdi-icon {
    color: $col_good_contrast_green;
  }
}
</style>