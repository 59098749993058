<template>
  <div
    class="select"
    :id="ref"
    :style="{ width: width + 'px' }"
    :class="[
      { gray: !isWhite },
      { disabled: disabled },
      { inline: isInline },
      className,
    ]">
    <div class="select__field" :ref="ref" @click="toggleSelect">
      {{ value }}
      <Icon :path="mdiMenuDown" :size="16" />
    </div>
    <Teleport to="body">
      <Transition name="slide-fade">
        <div
          v-if="ifExpanded"
          class="select__options"
          :class="className ? `${className} + '__options'` : null"
          :style="{ ...position, width: width + 'px' }">
          <template v-if="options.length > 0">
            <div
              v-for="(option, i) in options"
              :key="option.id ?? i"
              @click="onChange(option)"
              class="select__option"
              :class="{
                active: value === option.value || value === option.identifier,
              }">
              <Icon
                v-if="option.icon"
                :path="option.icon"
                :size="16"
                :style="{ color: barChartColours(options.length)[i] }" />
              <p>{{ option[displayByKey] }}</p>
            </div>
          </template>
          <p class="select__no-options" v-else>No options</p>
        </div>
      </Transition>
    </Teleport>
  </div>
</template>

<script>
  import { barChartColours } from "@/helpers/chartColours";
  import DropdownMixin from "@/mixins/DropdownMixin";

  export default {
    mixins: [DropdownMixin],
    props: {
      options: {
        type: Array,
      },
      displayByKey: {
        type: String,
        default: "name",
      },
      isInline: {
        type: Boolean,
        default: false,
      },
    },

    methods: {
      barChartColours,
      onChange(target) {
        this.$emit("onChange", target);
        this.ifExpanded = false;
      },
    },
  };
</script>

<style lang="scss">
  .select {
    @include custom-dropdown($optionsWidth: 100);

    &__no-options {
      width: 100%;
      text-align: center;
      color: gray;
      cursor: default;
      @include reset-padding-margin;
    }
  }
</style>
