<template>
  <div class="aggrid-data-invalid">
    {{ text }}
    <TextButton
      is-secondaryColor
      text="Reload"
      @click="$emit('reload')"
    />
  </div>
</template>

<script>
import TextButton from "@/components/Buttons/TextButton";

export default {
  props: {
    text: {
      type: String,
      default: "Oh no, something went wrong, please try again later!"
    }
  },
  emits: ['reload'],
  components: {
    TextButton
  }
}
</script>

<style lang="scss">
.aggrid-data-invalid {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  min-height: 200px;
  z-index: 9;
  @include flex-center($direction: column);
  text-align: center;
}
</style>