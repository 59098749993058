<template>
  <table class="calc">
    <tr class="formula">
      <td>Adjusted Revenue</td>
      <td>-</td>
      <td>Cash</td>
      <td>-</td>
      <td>External Card</td>
      <td>-</td>
      <td>Promotion</td>
    </tr>
    <tr class="values">
      <td>
        {{ formatMoney(data.financial.adjustedTotal) }}
      </td>
      <td>-</td>
      <td>{{ formatMoney(data.financial.grossRevenue.cash) }}</td>
      <td>-</td>
      <td>{{ formatMoney(data.financial.grossRevenue.cardExternal) }}</td>
      <td>-</td>
      <td>{{ formatMoney(data.financial.grossRevenue.promotion) }}</td>
    </tr>
  </table>
</template>

<script>
  export default {
    props: { data: { type: Object } },
  };
</script>

<style lang="scss" scoped>
  table.calc {
    @include calc-table;
  }
</style>
