import { mdiMenuDown } from "@mdi/js";

export default {
  data() {
    return {
      mdiMenuDown,
      ref: null,
      excludedRefForClickEvent: null, // Will be excluded in ClickOutsideListener()
      ifExpanded: false,
      position: {},
    };
  },

  props: {
    value: {
      // type: String,
    },
    required: {
      type: Boolean,
      default: false,
    },
    width: {
      type: Number,
      default: 280,
    },
    isWhite: {
      type: Boolean,
      default: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    className: {
      type: String,
    },
  },

  watch: {
    ifExpanded: {
      handler(newValue) {
        if (newValue) {
          const { left, top, bottom } =
            this.$refs[this.ref].getBoundingClientRect();

          const viewHeight = window.innerHeight;

          if (viewHeight - bottom < 250) {
            this.position = {
              left: left + "px",
              bottom: viewHeight - top + "px",
            };
          } else {
            this.position = {
              left: left + "px",
              top: bottom + "px",
            };
          }
        }
        return (document.querySelector("body").style.overflow = newValue
          ? "hidden"
          : "unset");
      },
      immediate: true,
      deep: true,
    },
  },

  methods: {
    toggleSelect() {
      if (this.disabled) return;
      this.ifExpanded = !this.ifExpanded;
    },
    clickOutsideListener(event) {
      const component = this.$refs[this.ref];
      const excludedComponent = this.$refs[this.excludedRefForClickEvent];

      if (
        !event.composedPath().includes(component) &&
        !event.composedPath().includes(excludedComponent)
      ) {
        this.ifExpanded = false;
      }
    },
  },

  emits: ["onChange"],

  mounted() {
    const id = Math.floor(Math.random() * 100);
    this.ref = "selector" + id;
    this.excludedRefForClickEvent = "included-ref" + id;
    window.addEventListener("click", this.clickOutsideListener);
  },

  beforeUnmount() {
    window.removeEventListener("click", this.clickOutsideListener);
  },
};
