<template>
  <div class="filter">
    <Icon :path="mdiFilter" :size="14" />
    <p>{{ title }}</p>
    <FilterToggleButton
      v-for="option in options"
      :key="option"
      :text="option"
      :active="value === option"
      @click="$emit('onChange', { option, title })" />
  </div>
</template>

<script>
  import { mdiFilter } from "@mdi/js";
  import FilterToggleButton from "@/components/Buttons/FilterToggleButton.vue";

  export default {
    data() {
      return {
        mdiFilter,
      };
    },

    emits: ["onChange"],
    components: { FilterToggleButton },

    props: {
      title: {
        type: String,
      },
      value: {
        type: String,
      },
      options: {
        type: Array,
      },
    },
  };
</script>

<style lang="scss">
  .filter {
    padding-right: 1rem;
    @include flex-center($jc: flex-start);

    p {
      @include reset-padding-margin;
      color: $col_softblack;
    }
  }
</style>
