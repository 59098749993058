<template>
  <div class="invoice-table">
    <div class="invoice-table__action">
      <DownloadFile
        :disabled="rowData.length === 0 || selectedRows.length === 0"
        :text="'Download (' + selectedRows.length + ')'"
        :path="mdiDownload"
        @onDownloadEnd="onDownloadEnd"
        :apiConfig="{
          endpoint: 'invoices',
          action: 'download',
          params: getDownloadParams,
        }" />
      <div class="aggrid-filters">
        <AggridExternalFilter
          @onChange="externalFilterChanged"
          :value="
            selectedStatusFilter
              ? 'paid'
              : selectedStatusFilter === false
              ? 'unpaid'
              : null
          "
          title="Status"
          :options="statusOptions" />
      </div>
    </div>

    <p v-if="downloadFileError" class="error-text">{{ downloadFileError }}</p>

    <AgGridVue
      class="ag-theme-alpine data-table__content auto-height"
      style="width: 100%"
      :columnDefs="columnDefs"
      rowSelection="multiple"
      :rowMultiSelectWithClick="true"
      :rowData="rowData"
      :defaultColDef="defaultColDef"
      @gridReady="onGridReady"
      @selection-changed="onSelectionChanged"
      :loadingOverlayComponent="loadingOverlayComponent"
      :isExternalFilterPresent="isExternalFilterPresent"
      :doesExternalFilterPass="doesExternalFilterPass"
      :pinnedBottomRowData="pinnedBottomRowData">
    </AgGridVue>
  </div>
</template>

<script>
  import Card from "@/components/Card.vue";
  import CustomDropDown from "@/components/CustomDropDown.vue";
  import IconButton from "@/components/Buttons/IconButton.vue";
  import DownloadFile from "@/components/AggridComponents/DownloadFile.vue";
  import Status from "@/components/AggridComponents/Status.vue";
  import ReceivingEntity from "@/components/AggridComponents/ReceivingEntity.vue";
  import Money from "@/components/AggridComponents/Money.vue";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import RowDataInvalid from "@/components/AggridComponents/RowDataInvalid.vue";
  import { AgGridVue } from "ag-grid-vue3";
  import { mdiDownload } from "@mdi/js";
  import { upperCamelCaseToString } from "@/helpers/stringFormatter.js";
  import AggridExternalFilter from "@/components/AggridComponents/AggridExternalFilter.vue";
  import { mapGetters } from "vuex";

  export default {
    data() {
      return {
        loading: true,
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),
        period: {
          start: this.$route?.params?.dateFrom,
          end: this.$route?.params?.dateTo,
        },

        gridApi: null,
        columnApi: null,
        rowData: [],
        columnDefs: [
          /* eslint-disable vue/no-unused-components */
          {
            field: "reference",
            headerName: "Statement",
            headerCheckboxSelection: true,
            checkboxSelection: true,
            width: 300,
            flex: 0,
            cellRendererSelector: ({ node, data }) =>
              node.rowPinned
                ? null
                : {
                    component: "DownloadFile",
                    params: {
                      onDownloadEnd: this.onDownloadEnd,
                      apiConfig: {
                        endpoint: "invoices",
                        action: "download",
                        params: {
                          references: [data.reference],
                          ownership: this.getOwnershipReference(),
                        },
                      },
                    },
                  },
          },
          {
            field: "type",
            headerName: "Type",
            width: 200,
            valueFormatter: ({ value }) => upperCamelCaseToString(value),
          },
          {
            field: "paid",
            headerName: "Status",
            width: 100,
            cellRendererSelector: ({ node, data }) =>
              node.rowPinned
                ? null
                : {
                    component: "Status",
                    params: {
                      status: data.value ? "paid" : "unpaid",
                    },
                  },
          },
          {
            field: "from",
            headerName: "From",
            valueGetter: ({ node, data }) =>
              node.rowPinned ? null : data?.from?.name,
          },
          {
            field: "to",
            headerName: "To",
            valueGetter: ({ node, data }) =>
              node.rowPinned ? null : data?.to?.name,
            cellRendererSelector: ({ node }) =>
              node.rowPinned ? null : { component: "ReceivingEntity" },
          },

          {
            field: "amount",
            headerName: "Amount",
            pinned: "right",
            cellRendererSelector: ({ node, data }) => {
              return node.rowPinned
                ? { component: "Money", params: { text: "Payout" } }
                : { component: "Money", params: data.amount };
            },
          },
        ],
        defaultColDef: {
          sortable: true,
          resizable: true,
          filter: true,
          width: 150,
          flex: 1,
          valueFormatter: ({ node, value }) =>
            node.rowPinned ? value : value || "-",
        },
        selectedRows: [],
        statusOptions: ["paid", "unpaid"],
        loadingOverlayComponent: "LoadingSpinner",
        rowDataInvalid: false,
        rowDataInvalidText: null,
        isFullWidthRow: null,
        fullWidthCellRenderer: null,
        selectedStatusFilter: null,
        selectedTypeFilter: null,
        downloadFileError: null,

        mdiDownload,
      };
    },

    props: {
      rowDataProps: { type: Array },
      invoiceTotal: { type: Number },
    },

    computed: {
      ...mapGetters({
        selectedOperatorName: "selectedOperator/getName",
      }),

      getDownloadParams() {
        return {
          references: this.selectedRows.map(({ reference }) => reference),
          ownership: this.getOwnershipReference(),
        };
      },

      pinnedBottomRowData() {
        return [{ amount: this.invoiceTotal }];
      },
    },

    methods: {
      externalFilterChanged({ option, title }) {
        if (title === "Status") {
          let newValue = option;
          newValue = option === "unpaid" ? false : true;
          this.selectedStatusFilter =
            newValue === this.selectedStatusFilter ? null : newValue;
        }

        this.gridApi.onFilterChanged();
      },

      isExternalFilterPresent() {
        return this.selectedStatusFilter;
      },

      doesExternalFilterPass(node) {
        if (!this.selectedStatusFilter) return true;
        if (node.data) return node.data.status === this.selectedStatusFilter;
        return true;
      },

      onDownloadEnd({ error }) {
        // arguements: {error, docUrl} can be accessed in here

        if (!error) return;

        const { response, message } = error;
        if (response.status === 500) {
          this.downloadFileError = message;
        }
      },

      onSelectionChanged() {
        const selectedRows = this.gridApi.getSelectedRows();
        this.selectedRows = selectedRows;
      },

      displayData(period = this.period) {
        this.loading = true;
        this.gridApi.showLoadingOverlay();

        const { start, end } = period;

        if (!start || !end) return;

        this.rowData = this.rowDataProps;
      },

      getOwnershipReference() {
        return `#${this.operatorType}:${this.operatorId}`;
      },

      onGridReady(params) {
        this.gridApi = params.api;
        this.gridColumnApi = params.columnApi;
        this.displayData();
      },
    },

    mounted() {
      this.loading = true;
    },

    beforeUnmount() {
      this.gridApi = null;
    },

    components: {
      Card,
      AgGridVue,
      IconButton,
      CustomDropDown,
      DownloadFile,
      Status,
      ReceivingEntity,
      Money,
      LoadingSpinner: LoadingSpinner,
      RowDataInvalid,
      AggridExternalFilter,
    },
  };
</script>

<style lang="scss">
  .invoice-table {
    width: 100%;

    &__action {
      @include flex-initial;
      margin-top: 1rem;

      button:first-child {
        padding-left: 0;
      }
    }

    .error-text {
      margin-bottom: 0.5rem !important;
    }

    .aggrid-filters {
      @include flex-initial;
      width: 100%;
      flex: 1;
    }

    .ag-theme-alpine .ag-ltr .ag-selection-checkbox {
      margin-right: 0;
    }
  }

  .ag-floating-bottom {
    width: 100% !important;

    .ag-floating-bottom-viewport {
      display: none;
    }

    .ag-pinned-right-floating-bottom {
      width: 100% !important;
      max-width: unset !important;

      .ag-cell[col-id="amount"] {
        width: 100% !important;
        text-align: right;
        font-weight: bold;
        font-size: 1.2rem;
      }
    }
  }
</style>
