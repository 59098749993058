<template>
  <span class="explanation"><slot></slot></span>
</template>

<script></script>

<style lang="scss">
  .explanation {
    font-size: 0.85rem;
    color: $col_faded-darker;
    position: absolute;
    padding: 0.75rem;
    font-weight: normal;
    top: -60px;
    width: max-content;
    background: white;
    border-radius: $card_radius;
    box-shadow: $card_shadow;
    left: 0px;
    width: 210px;
    justify-content: space-around;
    gap: 0.5rem;

    .formula {
      gap: 0;
      text-align: center;
      .divide {
        width: 100%;
        border-bottom: 1px solid $col_faded-darker;
      }
    }
  }
</style>
