<template>
  <div
    v-if="params.status"
    class="status"
    @mouseenter="params.status === 'failed' ? this.showMessage = true : null"
    @mouseleave="params.status === 'failed' ? this.showMessage = false : null"
    :class="params.status"
  >
    <Icon 
      v-if="params.ifShowIcon"
      :path="path"
      :size="16"
    />
    {{ params.status }}
    <Transition name="fade">
      <div
        class="status__message"
        v-show="showMessage"
      >
        <h5>Failed Message</h5>
        <p>Failed Message</p>
      </div>
    </Transition>

  </div>
  <div v-else>-</div>
</template>

<script>
import { mdiCheckCircle, mdiReceiptTextClock, mdiAlertCircle } from '@mdi/js';

export default {
  data() {
    return {
      showMessage: false,
      mdiCheckCircle
    }
  },

  computed: {
    path() {
      switch (this.params.status) {
        case 'paid':
          return mdiCheckCircle;
        case 'failed':
          return mdiAlertCircle;
        case 'unpaid':
          return mdiReceiptTextClock;
        default:
          return null;
      }
    }
  },
}
</script>

<style lang="scss">
.status {
  @include flex-center;
  text-transform: capitalize;
  position: relative;

  &.paid {
    color: $col_good_contrast_green;
  }

  &.unpaid {
    color: $col_alpha;
  }

  &.failed {
    color: $col_alpha-darker;
  }

  &__message {
    position: absolute;
    background: white;
    padding: 0.25rem 1rem 1rem 1rem;
    border-radius: $card_radius;
    box-shadow: $card_shadow;
    top: 2rem;
    left: 0;

    * {
      margin: 0;
      padding: 0;
      text-align: left;
    }

    p {
      color: black;
    }
  }
}

.ag-row {
  z-index: 0;
}

.ag-row.ag-row-hover {
  z-index: 1;
}
</style>