<template>
  <div
    v-if="params.value"
    class="money"
    :class="{ minus: params.value < 0 }"
  >
    <span
      class="label"
      v-if="params.text"
    >{{ params.text }}</span>
    {{ this.formatMoney(params.value) }}
  </div>
  <div v-else>-</div>
</template>

<script>

export default {
}
</script>

<style lang="scss">
.money {
  width: 100%;
  text-align: right;

  .label {
    padding-right: 0.5rem;

    @include themed() {
      color: t($text);
    }

  }

  &.minus {
    color: $col_alpha;
  }
}
</style>