<template>
  <div class="main-content statement">
    <h3 class="page-heading">Invoice Statement</h3>

    <div class="page-action">
      <CustomDropDown
        :options="periodOptions"
        :value="dateRangeFormatter(period.start, period.end)"
        :disabled="loading"
        :width="250"
        @onChange="updateDateRange" />

      <button @click="updateRoute">Update</button>

      <DownloadFile
        class="download"
        :disabled="!data.invoices.length"
        :text="'Download Invoices (' + data.invoices.length + ')'"
        :isTextStyle="false"
        :isBlack="false"
        @onDownloadEnd="onDownloadEnd"
        :apiConfig="{
          endpoint: 'invoices',
          action: 'download',
          params: getDownloadParams,
        }" />
      <p v-if="downloadFileError" class="error-text">{{ downloadFileError }}</p>
    </div>

    <div class="centered-loading-wrapper" v-if="loading">
      <LoadingSpinner />
    </div>

    <Card
      v-else
      class="statement__body"
      :title="periodTitle"
      flexDirection="column"
      :contentGap="0">
      <template v-slot:upper-right>
        <StatementStatus v-if="!isSealed" />
        <IconButton
          v-else
          :path="mdiDownload"
          text="Download Statement"
          is-textStyle
          is-black />
      </template>

      <template v-slot:content>
        <div class="statement__summary">
          <FinanceSummary
            :adjustedTotal="data.financial.adjustedTotal"
            :serviceRevenue="data.financial.serviceRevenue"
            :fundsHeld="data.financial.fundsHeld"
            :invoiceTotal="invoiceTotal"
            v-if="data.financial" />
          <CountSummary :data="data" v-if="data.count" />
        </div>

        <template v-if="data.financial">
          <h4>Revenue</h4>
          <InvoicesStatementDetails
            :rows="{ count: data.count, financial: data.financial }" />
        </template>
        <template v-if="data?.invoices != null && data.invoices.length">
          <h4>Invoices</h4>
          <FundsHeldTable
            v-if="this.selectedOperatorType == 'outlet'"
            :data="data" />
          <InvoicesTable
            :rowDataProps="data.invoices"
            :invoiceTotal="invoiceTotal" />
        </template>
      </template>
    </Card>
  </div>
</template>

<script>
  import store from "@/store";
  import Card from "@/components/Card.vue";
  import IconButton from "@/components/Buttons/IconButton.vue";
  import StatementStatus from "@/components/Statements/StatementStatus.vue";
  import LoadingSpinner from "@/components/LoadingSpinner.vue";
  import CustomDropDown from "@/components/CustomDropDown.vue";
  import { useToast } from "vue-toastification";
  import { mapGetters } from "vuex";
  import InvoicesStatementDetails from "@/components/ServiceStatements/InvoicesStatementDetails.vue";
  import InvoicesTable from "@/components/Invoice/InvoicesTable.vue";
  import DownloadFile from "@/components/AggridComponents/DownloadFile.vue";
  import CountSummary from "@/components/Invoice/CountSummary.vue";
  import FinanceSummary from "@/components/Invoice/FinanceSummary.vue";
  import FundsHeldTable from "@/components/Invoice/FundsHeldTable.vue";

  export default {
    data() {
      return {
        loading: true,
        operatorType: this.$route.params.operatorType,
        operatorId: parseInt(this.$route.params.operatorId),

        isSealed: false,
        period: {
          start: this.$route?.params?.dateFrom,
          end: this.$route?.params?.dateTo,
        },
        periodOptions: [],

        data: {
          count: null,
          financial: null,
          invoices: [],
        },

        countBasedRows: [
          "serviceCount",
          "cartCount",
          "eventCount",
          "orderCount",
        ],

        downloadFileError: null,
      };
    },

    computed: {
      ...mapGetters({
        selectedOperatorId: "selectedOperator/getId",
        selectedOperatorType: "selectedOperator/getType",
        selectedOperatorName: "selectedOperator/getName",
      }),

      periodTitle() {
        const { dateFrom, dateTo } = this.$route?.params;

        if (!dateFrom || !dateTo) {
          const { start, end } = this.period;
          return this.dateRangeFormatter(start, end);
        }

        return this.dateRangeFormatter(dateFrom, dateTo);
      },

      getDownloadParams() {
        return {
          references: this.data.invoices.map(({ reference }) => reference),
          ownership: this.getOwnershipReference(),
        };
      },

      invoiceTotal() {
        // If no invoice0s generated, calculate the total from financial data from api response
        if (!this.data.invoices.length) {
          return null;
        }

        let amount = 0;
        this.data.invoices.forEach((invoice) => {
          amount += invoice.amount;
        });

        return amount;
      },
    },

    methods: {
      onDownloadEnd({ error }) {
        if (error == null) {
          return;
        }

        // arguements: {error, docUrl} can be accessed in here
        const { response } = error;

        if (!error) return;

        if (response.status === 500) {
          this.downloadFileError = "Download failed.";
        }
      },

      dateRangeFormatter(start, end) {
        return this.formatDate(start) + " - " + this.formatDate(end);
      },

      updateDateRange(newPeriod) {
        this.period = {
          start: newPeriod.startDate,
          end: newPeriod.endDate,
        };
      },

      updateRoute() {
        const { dateFrom, dateTo } = this.$route?.params;
        const { start, end } = this.period;

        if (start === dateFrom && end === dateTo) {
          return this.fetchStatement();
        }

        // Avoid button being double-clicked causing unnecessary endpoints calling while page refreshing
        this.loading = true;

        this.$router.push({
          params: {
            operatorType: this.selectedOperatorType,
            operatorId: this.selectedOperatorId,
            dateFrom: start,
            dateTo: end,
          },
        });
      },

      getOwnershipReference() {
        return `#${this.operatorType}:${this.operatorId}`;
      },

      getAllPeriods() {
        const query = {
          ownership: this.getOwnershipReference(),
          mode: "Earliest",
        };

        store.state.apiPrivate.client.endpoints.invoicePeriods
          .query(query)
          .then((response) => {
            if (response.status >= 200 && response.status <= 204) {
              const data = response.data.data;
              this.periodOptions = data.map((dateObj) => ({
                ...dateObj,
                name: this.dateRangeFormatter(
                  dateObj.startDate,
                  dateObj.endDate
                ),
              }));
            } else {
              Promise.reject({ query, response });
            }
          })
          .catch((error) => {
            useToast().error("Failed to find recent invoicing period.");
            window.log.error("Failed to find recent invoicing period.", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      redirectToLatest() {
        return store.state.apiPrivate.client.endpoints.invoicePeriods
          .query({
            ownership: this.getOwnershipReference(),
            mode: "MostRecent",
            count: 1,
          })
          .then((response) => {
            if (response.status >= 200 && response.status <= 204) {
              this.$router.push({
                name: "/invoices/dates",
                params: {
                  operatorType: this.operatorType,
                  operatorId: this.operatorId,
                  dateFrom: response.data.data[0]?.startDate,
                  dateTo: response.data.data[0]?.endDate,
                },
              });
            } else {
              Promise.reject("Failed to find recent invoicing period.");
            }
          })
          .catch((error) => {
            useToast().error("Failed to find recent invoicing period.");
            window.log.error("Failed to find recent invoicing period.", error);
          })
          .finally(() => {
            this.loading = false;
          });
      },

      setRowsByTypes(apiData) {
        const data = {
          count: {},
          financial: {},
          invoices: [],
          cartConversionRate: 0,
        };

        for (const key in apiData) {
          if (this.countBasedRows.includes(key)) {
            data.count[key] = apiData[key];
            continue;
          }

          if (key === "cartConversionRate") {
            if (apiData["cartCount"] === 0) {
              data.cartConversionRate = "-";
              continue;
            }

            const conversionRate =
              (apiData["orderCount"] / apiData["cartCount"]) * 100;
            data.cartConversionRate = conversionRate.toFixed(2) + "%";
            continue;
          }

          if (key === "invoices") {
            data.invoices = apiData[key];
            continue;
          }

          data.financial[key] = apiData[key];
        }

        this.data = data;
      },

      async fetchStatement() {
        const { start, end } = this.period;

        if (!start || !end) return;

        return store.state.apiPrivate.client.endpoints.statements
          .query({
            [this.operatorType + "Ids"]: [this.operatorId],
            dateFrom: start,
            dateTo: end,
            includeInvoices: true,
            groupByParentCompany: this.operatorType == "venue",
          })
          .then((response) => {
            if (response.status >= 200 && response.status <= 204) {
              const { dateFrom, dateTo, ...data } = response.data.data;

              this.setRowsByTypes(data);

              this.period = { start: dateFrom, end: dateTo };
            } else {
              if (response.status === 404) {
                this.data = null;
              }
              return Promise.reject(response);
            }
          })
          .catch((error) => {
            if (error.status !== 404) {
              useToast().error("Failed to download invoices.");
              window.log.error("Failed to download invoices.", error);
            }
          })
          .finally(() => {
            this.loading = false;
          });
      },
    },

    async beforeMount() {
      if (this.$router.currentRoute._value.name == "/invoices") {
        await this.redirectToLatest();
      }
    },

    mounted() {
      this.loading = true;
      this.getAllPeriods();
      this.fetchStatement();
    },
    beforeUnmount() {
      this.gridApi = null;
    },

    components: {
      Card,
      IconButton,
      StatementStatus,
      LoadingSpinner,
      CustomDropDown,
      InvoicesStatementDetails,
      InvoicesTable,
      DownloadFile,
      CountSummary,
      FinanceSummary,
      FundsHeldTable,
    },
  };
</script>
<style lang="scss" scoped>
  .page-action {
    @include flex-initial(
      $direction: row,
      $alignItems: center,
      $gap: 1rem,
      $wrap: wrap
    );
    margin-bottom: 1rem;

    button.download {
      @include contained-button;
    }
  }

  h4 {
    border-bottom: 3px solid rgb(255, 125, 125);
  }
</style>

<style lang="scss">
  .red {
    color: $col_alpha-darker;
  }

  .statement {
    &__body {
      .card__title {
        > .slot {
          flex: 1;
        }
      }

      .card__content {
        height: calc(100% - 4rem);
        align-items: baseline;
      }
    }

    .error-text {
      color: $col_error;
      font-size: 0.875rem;
      margin: 0;
      padding: 0;
    }

    table {
      border-collapse: collapse;

      tr {
        td {
          padding: 0.25rem 0.5rem;
        }
      }

      &.invoices-statement {
        > tbody {
          border: $table_border;
          > td {
            padding: 0.5rem;
            line-height: 1.2;
          }

          td {
            &:first-child {
              width: 30%;
              text-align: left;
              padding-left: 0.5rem;

              h3 {
                font-size: 1rem;
                font-weight: bold;
                margin: 0;
              }
            }

            &.row {
              width: 100% !important;
              justify-content: space-between;

              h3 {
                flex: 1;
              }
            }
          }
        }
      }
      h3 {
        position: relative;
        @include flex-initial($gap: 0.25rem, $alignItems: center);
      }
    }
  }
</style>
