<template>
  <table class="calc">
    <tr class="formula">
      <td>Gross Revenue</td>
      <td>-</td>
      <td>Charity Donations</td>
      <td>-</td>
      <td>Refunds</td>
      <td>-</td>
      <td>Cancellations</td>
    </tr>
    <tr class="values">
      <td>
        {{ formatMoney(data.financial.grossRevenue.total) }}
      </td>
      <td>-</td>
      <td>{{ formatMoney(data.financial.charityDonations) }}</td>
      <td>-</td>
      <td>{{ formatMoney(data.financial.refunds) }}</td>
      <td>-</td>
      <td>
        {{ formatMoney(data.financial.cancellations.total) }}
      </td>
    </tr>
  </table>
</template>

<script>
  export default {
    props: { data: { type: Object } },
  };
</script>

<style lang="scss" scoped>
  table.calc {
    @include calc-table;
  }
</style>
