<template>
  <div class="statement__summary-finance">
    <!-- <div class="statement__summary-data">
      <p class="title">Adjusted Revenue</p>
      <p class="value" :class="{ red: adjustedTotal < 0 }">
        {{ formatMoney(adjustedTotal) }}
      </p>
    </div> -->
    <div class="statement__summary-data">
      <p class="title">Service Revenue</p>
      <p class="value" :class="{ red: serviceRevenue < 0 }">
        {{ formatMoney(serviceRevenue) }}
      </p>
    </div>
    <!-- <div class="statement__summary-data">
      <p class="title">Funds Held</p>
      <p class="value" :class="{ red: fundsHeld < 0 }">
        {{ formatMoney(fundsHeld) }}
      </p>
    </div> -->
    <div class="statement__summary-data" v-if="invoiceTotal != null">
      <p class="title">Payout</p>
      <p class="value" :class="{ red: invoiceTotal < 0 }">
        {{ formatMoney(invoiceTotal) }}
      </p>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      adjustedTotal: { type: Number },
      serviceRevenue: { type: Number },
      fundsHeld: { type: Number },
      invoiceTotal: { type: Number },
    },
  };
</script>

<style lang="scss">
  .statement__summary {
    padding: 0.5rem;
    border: $table_border;
    width: 100%;
    @include flex-initial;

    &-finance {
      @include flex-initial($wrap: wrap);
    }

    &-finance {
      border-right: $table_border;
      padding-right: 1rem;
    }

    &-data {
      text-transform: capitalize;
      min-width: 130px;
      @include flex-center($jc: flex-start, $direction: column);

      &.finance {
        border-right: $table_border;
        padding-right: 1.5rem;
      }

      p {
        margin: 0;
        line-height: 1;
        &.title {
          @include flex-initial($gap: 0.25rem, $alignItems: center);
          color: $col_faded-darker;
          font-size: 0.75rem;
        }
        &.value {
          font-weight: 600;
          font-size: 1.1rem;
        }
      }

      &.rate {
        flex-wrap: wrap;
        position: relative;
      }
    }
  }
</style>
