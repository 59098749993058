<template>
  <table class="invoices-statement">
    <thead>
      <tr>
        <th></th>
        <th v-for="channel in rows.financial.channels" :key="channel">
          {{ this.formatChannel(channel) }}
        </th>
        <th>Total</th>
      </tr>
    </thead>
    <tbody>
      <!-- <RowChannelPaymentType
        name="Payments"
        :total="rows.financial.payments"
        :channels="rows.financial.channels"
        :byChannel="rows.financial.paymentsChannel" /> -->

      <tr class="title">
        <td :colspan="rows.financial.channels.length + 1">
          <h3>Customer Service Charge</h3>
        </td>
        <td class="red">
          {{ formatMoney(rows.financial.serviceCharge.customer * -1) }}
        </td>
      </tr>

      <tr class="title">
        <td :colspan="rows.financial.channels.length + 1">
          <h3>Outlet Service Charge</h3>
        </td>
        <td class="red">
          {{ formatMoney(rows.financial.serviceCharge.outlet * -1) }}
        </td>
      </tr>

      <RowChannelPaymentType
        name="Gross Revenue"
        :total="rows.financial.grossRevenue"
        :channels="rows.financial.channels"
        :byChannel="rows.financial.grossRevenueChannel" />

      <tr class="title">
        <td :colspan="rows.financial.channels.length + 1">
          <h3>Charity Donations</h3>
        </td>
        <td class="red">
          {{ formatMoney(this.rows.financial.charityDonations * -1) }}
        </td>
      </tr>

      <tr class="title">
        <td :colspan="rows.financial.channels.length + 1"><h3>Refunds</h3></td>
        <td class="red">
          {{ formatMoney(this.rows.financial.refunds * -1) }}
        </td>
      </tr>

      <RowCancellations
        name="Cancellations"
        :cancellations="this.rows.financial.cancellations"
        :channels="this.rows.financial.channels" />

      <tr>
        <td>
          <h3 @mouseleave="explainAdjustedRevenue = false">
            Adjusted Revenue
            <Icon
              :path="mdiAlertCircleOutline"
              @mouseover="explainAdjustedRevenue = true"
              :size="14" />
            <SmallExplainPopup v-show="explainAdjustedRevenue"
              >Commission is based on this figure.</SmallExplainPopup
            >
          </h3>
        </td>
        <td :colspan="rows.financial.channels.length" class="right">
          <span class="row">
            <CommissionCalcTable :data="this.rows" />
          </span>
        </td>
        <td class="fit">
          <h4>
            {{ formatMoney(this.rows.financial.adjustedTotal) }}
          </h4>
        </td>
      </tr>

      <tr>
        <td :colspan="rows.financial.channels.length + 1">
          <h3>Total Commissions</h3>
        </td>
        <td class="fit">
          <span class="red">
            {{ formatMoney(this.rows.financial.commissionTotal * -1) }}
          </span>
        </td>
      </tr>

      <RowProcessingFee
        name="Processing Fee"
        :total="this.rows.financial.processingFee"
        :channels="rows.financial.channels"
        :byChannel="rows.financial.processingFeeChannel" />

      <tr class="title">
        <td :colspan="rows.financial.channels.length + 1">
          <h3>Promotion Contribution</h3>
        </td>
        <td>
          <span class="red">
            {{ formatMoney(this.rows.financial.promotionContribution * -1) }}
          </span>
        </td>
      </tr>

      <tr class="title">
        <td>
          <h3 @mouseleave="explainServiceRevenue = false">
            Service Funds
            <Icon
              :path="mdiAlertCircleOutline"
              @mouseover="explainServiceRevenue = true"
              :size="14" />
            <SmallExplainPopup v-show="explainServiceRevenue"
              >This is the total funds generated during this
              period.</SmallExplainPopup
            >
          </h3>
        </td>
        <td :colspan="rows.financial.channels.length" class="right">
          <span class="row">
            <ServiceRevenueCalcTable :data="this.rows" />
          </span>
        </td>
        <td>
          <h4>
            {{
              formatMoney(
                this.rows.financial.adjustedTotal -
                  this.rows.financial.commissionTotal -
                  this.rows.financial.processingFee.total -
                  this.rows.financial.promotionContribution
              )
            }}
          </h4>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script>
  import { mdiAlertCircleOutline } from "@mdi/js";
  import { formatChannel } from "@tucktrucks/platform-base-private";
  import RowChannelPaymentType from "@/components/ServiceStatements/RowChannelPaymentType.vue";
  import RowCancellations from "@/components/ServiceStatements/RowCancellations.vue";
  import CommissionCalcTable from "@/components/Invoice/CommissionCalcTable.vue";
  import ServiceRevenueCalcTable from "@/components/Invoice/ServiceRevenueCalcTable.vue";
  import SmallExplainPopup from "@/components/Dialogs/SmallExplainPopup.vue";
  import RowProcessingFee from "@/components/ServiceStatements/RowProcessingFee.vue";

  export default {
    data() {
      return {
        mdiAlertCircleOutline,
        explainAdjustedRevenue: false,
        explainServiceRevenue: false,
        formatChannel,
      };
    },

    props: { rows: { type: Object } },

    components: {
      RowChannelPaymentType,
      RowCancellations,
      CommissionCalcTable,
      ServiceRevenueCalcTable,
      SmallExplainPopup,
      RowProcessingFee,
    },
  };
</script>
<style lang="scss">
  table.invoices-statement {
    width: 100%;
    @include striped-row-table;

    tr {
      width: 100%;

      th,
      td {
        width: 15%;
        text-align: right;

        &.right {
          .row {
            justify-content: flex-end;
            padding-right: 1rem;
          }
        }
      }
      th {
        font-size: 0.75rem;
        padding-right: 0.5rem;
        font-weight: normal;
        color: $col_faded-darker;
      }
      &.detail {
        > td {
          padding: 0 !important;

          table {
            td:first-child {
              padding-left: 0.5rem;
            }
          }
        }
      }
    }

    tfoot {
      font-weight: bold;
    }
  }
</style>
